
import kanban from "@/components/sales-pipeline/kanban.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
        kanban,
    },
})
export default class SalesPipeline extends Vue {}
