
  import { Component, Vue, Prop } from "vue-property-decorator";
  import { TeamFunctions } from "@/store/modules/activity-account.store";
  import { activityIsDossier } from "@/utils/model-helpers";

  export interface DealCard extends Activity {
    saving?: boolean;
  }

  @Component({
    components: {
      UserImage: () => import("@/components/general/user-image.vue"),
      UserImageWrapper: () => import("@/components/general/user-image-wrapper.vue"),
      AssignUsers: () => import("@/components/dossier/assign-users.vue"),
      DossierCommission: () => import("@/components/dossier/commission.vue"),
      DossierAction: () => import("@/components/dossier/action.vue"),
      WorkStatusTag: () => import("@/components/dossier/work-status-tag.vue"),
    },
  })
  export default class DealCardComponent extends Vue {
    @Prop({ default: () => {} }) deal!: DealCard;

    @Prop({ default: "full" }) displayType!: "full" | "compact";

    @Prop({ default: false }) showAdditionalWonStatus!: boolean;

    @Prop({ default: false }) showActions!: boolean;

    teamFunctions = TeamFunctions;

    get isDossier() {
      return activityIsDossier(this.deal as Activity);
    }

    get uniqueAssignees() {
      if (!this.isDossier) {
        return [...new Map(this.deal.assigned_to.filter((item) => item.type === TeamFunctions.buyer).map((item) => [item.id, item])).values()];
      }

      return [...new Map(this.deal.assigned_to.filter((item) => item.type === TeamFunctions.seller).map((item) => [item.id, item])).values()];
    }

    goToDetail() {
      this.$router.push({ name: "dossier-detail", params: { id: `${this.deal.id}` } });
    }
  }
