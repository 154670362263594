
  import { Component, Mixins } from "vue-property-decorator";
  import { default as dealCard } from "@/components/sales-pipeline/deal-card.vue";
  import type { DealCard } from "@/components/sales-pipeline/deal-card.vue";
  import { WorkStatus } from "@/store/modules/activity.store";
  import { bus } from "@/main";
  import vSelect from "vue-select";
  import FormDateRangePicker from "@/components/forms/date-range.vue";
  import OpenIndicator from "@/assets/svg/heroicons/selector.svg";
  import WonLostChart from "@/components/sales-pipeline/won-lost-chart.vue";
  import userSelect from "@/components/forms/user-select.vue";
  import userImage from "../general/user-image.vue";
  import userImageWrapper from "../general/user-image-wrapper.vue";
  import { formatCurrency } from "@/utils/formatters";
  import { HasKanban, KanbanType } from "@/mixins/has-kanban";
  import { getActivityCommission } from "@/utils/model-helpers";

  vSelect.props.components.default = () => ({ OpenIndicator });

  interface DealColumn {
    canAdd?: boolean;
    code: WorkStatus;
    deals: DealCard[];
    label?: string;
  }

  @Component({
    components: {
      dealCard,
      vSelect,
      FormDateRangePicker,
      userSelect,
      WonLostChart,
      userImage,
      userImageWrapper,
      GeneralHeaderBar: () => import("@/components/general/header-bar.vue"),
    },
  })
  export default class SalesPipelineKanban extends Mixins(HasKanban) {
    formatCurrency = formatCurrency;

    kanbanType: KanbanType = "sales-pipeline";

    initialWorkStatuses: WorkStatus[] = [
      WorkStatus.new,
      WorkStatus.contact,
      WorkStatus.estimate,
      WorkStatus.followUp,
      WorkStatus.followUpLT,
      WorkStatus.won,
      WorkStatus.lost,
      // WorkStatus.newDossier,
      // WorkStatus.startUp,
      // WorkStatus.done,
      // WorkStatus.published,
      // WorkStatus.firstOffer,
      // WorkStatus.option,
      // WorkStatus.sold,
      // WorkStatus.deed,
    ];

    wonColumns = [
      WorkStatus.won,
      WorkStatus.newDossier,
      WorkStatus.startUp,
      WorkStatus.done,
      WorkStatus.published,
      WorkStatus.firstOffer,
      WorkStatus.option,
      WorkStatus.sold,
      WorkStatus.deed,
    ];

    lostColumns = [WorkStatus.lost];

    get columns(): DealColumn[] {
      const unresolved = [
        { code: WorkStatus.new, deals: this.localActivities.filter((a) => a.work_status === WorkStatus.new), canAdd: true },
        { code: WorkStatus.contact, deals: this.localActivities.filter((a) => a.work_status === WorkStatus.contact) },
        { code: WorkStatus.estimate, deals: this.localActivities.filter((a) => a.work_status === WorkStatus.estimate), label: `${formatCurrency(this.estimageBudget)} — ` },
        { code: WorkStatus.followUp, deals: this.localActivities.filter((a) => a.work_status === WorkStatus.followUp), label: `${formatCurrency(this.followUpBudget)} — ` },
        { code: WorkStatus.followUpLT, deals: this.localActivities.filter((a) => a.work_status === WorkStatus.followUpLT), label: `${formatCurrency(this.followUpLTBudget)} — ` },
      ];
      const resolved = [
        { code: WorkStatus.won, deals: this.localActivities.filter((a) => this.wonColumns.includes(a.work_status)), label: `${formatCurrency(this.wonBudget)} — ` },
        { code: WorkStatus.lost, deals: this.localActivities.filter((a) => this.lostColumns.includes(a.work_status)), label: `${formatCurrency(this.lostBudget)} — ` },
      ];

      if (this.displayMode === "unresolved") {
        return unresolved.concat(resolved);
      }

      return resolved;
    }

    get wonBudget() {
      return this.localActivities
        .filter((a) => this.wonColumns.includes(a.work_status))
        .reduce((accumulator, activity) => {
          return accumulator + getActivityCommission(activity).commission;
        }, 0);
    }

    get lostBudget() {
      return this.localActivities
        .filter((a) => this.lostColumns.includes(a.work_status))
        .reduce((accumulator, activity) => {
          return accumulator + getActivityCommission(activity).commission;
        }, 0);
    }

    get wonLostData(): { won: { total: number; percent: number; budget: number }; lost: { total: number; percent: number; budget: number } } {
      const won = this.localActivities.filter((a) => this.wonColumns.includes(a.work_status));
      const lost = this.localActivities.filter((a) => this.lostColumns.includes(a.work_status));
      const total = won.length + lost.length;

      return {
        won: {
          total: won.length,
          percent: total ? Math.round((won.length / total) * 100) : 0,
          budget: this.wonBudget,
        },
        lost: {
          total: lost.length,
          percent: total ? Math.round((lost.length / total) * 100) : 0,
          budget: this.lostBudget,
        },
      };
    }

    switchDisplayMode(mode: "resolved" | "unresolved") {
      this.displayMode = mode;

      let statusses = this.initialWorkStatuses;
      if (mode === "resolved") {
        statusses = this.wonColumns.concat(this.lostColumns);
      }

      this.indexQuery.q.and.or.work_status = statusses;

      this.handleFilterChanged(this.filter);
    }

    addDeal() {
      bus.$emit("show-activity-create", { redirectToProperty: false, showProspectToggle: false });
    }

    conditionalFilteredDeals(deals: DealCard[], columnCode: WorkStatus) {
      if (deals.length === 0) {
        return [];
      }

      switch (columnCode) {
        case WorkStatus.new:
        case WorkStatus.contact:
          return deals.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
          break;
        case WorkStatus.estimate:
          return deals.sort((dealA: DealCard, dealB: DealCard) => {
            const appointmentActionA = dealA.actions.find((action) => action.type === "appointment");
            const appointmentActionB = dealB.actions.find((action) => action.type === "appointment");

            if (!appointmentActionA && !appointmentActionB) return 0;
            if (!appointmentActionA) return 1;
            if (!appointmentActionB) return -1;

            return new Date(appointmentActionA.deadline).getTime() - new Date(appointmentActionB.deadline).getTime();
          });
          break;
        case WorkStatus.followUp:
        case WorkStatus.followUpLT:
          return deals.sort((dealA: DealCard, dealB: DealCard) => {
            const taskActionA = dealA.actions.find((action) => action.type === "task");
            const taskActionB = dealB.actions.find((action) => action.type === "task");

            if (!taskActionA && !taskActionB) return 0;
            if (!taskActionA) return 1;
            if (!taskActionB) return -1;

            return new Date(taskActionA.deadline).getTime() - new Date(taskActionB.deadline).getTime();
          });
          break;
      }

      return deals;
    }
  }
